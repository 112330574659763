import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import textStyles from "../styles/text.module.scss"
import classNames from "classnames"
import { buttonHarness, buttonHarnessNarrow, button, checkoutButton } from '../styles/button.module.scss'
import { Link } from "gatsby"

const NotFoundPage = ({ location }) => (
  <Layout>
      <SEO title="404: not found" />
      <div className={classNames(textStyles.title, textStyles.big, textStyles.bigEmoji)}>
        😢
      </div>
      <div className={classNames(textStyles.body, textStyles.closeAtTop)}>
          We can't seem to find that page.
      </div>
      <div className={classNames(buttonHarness, buttonHarnessNarrow)}>
        <Link to='/' className={classNames(button, checkoutButton)}>Go back to the site</Link>
      </div>      
  </Layout>
)

export default NotFoundPage
